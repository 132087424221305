import { useCallback, useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { Outlet, useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import Header from 'src/containers/domains/app/elements/Header'
import Sidebar from 'src/containers/domains/app/elements/Sidebar'
import type { HubCapsule } from '@aws-amplify/core'
import { useClearStateAndQueryCaches } from 'src/common/hooks'
import type { ICognitoUser } from 'src/common/types/auth'
import { removeCookies } from 'src/common/helper/cookieManager'
import { useAuthenticateMutation } from 'src/services'
import { useShallowAuthenticateStore } from 'src/store/modules/authenticate'
import Dashboard from './elements/Dashboard'
import Main from './elements/Main'

const Authenticate: React.FC = () => {
  const [authState, setAuthState] = useState<'signedOut' | 'signedIn'>('signedOut')
  const [isOpen, toggleDrawer] = useState(true)
  const { handleClearStateAndQueryCaches } = useClearStateAndQueryCaches()
  const navigate = useNavigate()
  const location = useLocation()
  const { mutate: authMutate } = useAuthenticateMutation()
  const { isAuthenticated, setAuth } = useShallowAuthenticateStore()

  const handleToggleDrawer = useCallback((bool: boolean) => {
    toggleDrawer(!bool)
  }, [])

  const handleAuthStateChange = useCallback(
    async (event?: string) => {
      try {
        const user: ICognitoUser = await Auth.currentAuthenticatedUser()
        // 認可の実施
        if (!isAuthenticated) {
          authMutate(
            {},
            {
              onSuccess: () => {
                setAuthState(user.username ? 'signedIn' : 'signedOut')
                setAuth()
              }
            }
          )
        } else {
          setAuthState(user.username ? 'signedIn' : 'signedOut')
        }
      } catch (e) {
        setAuthState('signedOut')
        removeCookies()
        handleClearStateAndQueryCaches()

        // ユーザが自身でログアウトした場合、リダイレクトパスを付けない
        if (event === 'signOut') {
          navigate('/login', { replace: true })
        } else {
          if (window.location.pathname === '/login') return
          const redirectPath = `${location.pathname}${location.search}`
          navigate(`/login?redirect=${encodeURIComponent(redirectPath)}`, { replace: true })
        }
      }
    },
    [authMutate, handleClearStateAndQueryCaches, isAuthenticated, location, navigate, setAuth]
  )

  useEffect(() => {
    handleAuthStateChange()
  }, [handleAuthStateChange])

  useEffect(() => {
    const hubCallback = (data: HubCapsule): void => {
      const { channel, payload } = data
      if (channel === 'auth' && payload.event !== 'signIn') {
        handleAuthStateChange(payload.event)
      }
    }

    Hub.listen('auth', hubCallback)

    return () => {
      Hub.remove('auth', hubCallback)
    }
  }, [handleAuthStateChange])

  return (
    <>
      {authState === 'signedIn' ? (
        <Dashboard {...{ isOpen }}>
          <Header />
          <Sidebar {...{ isOpen, handleToggleDrawer }} />
          <Main>
            <Outlet />
          </Main>
        </Dashboard>
      ) : null}
    </>
  )
}

export default Authenticate
