import type { CognitoUser } from 'amazon-cognito-identity-js'
import { Formik, type FormikProps } from 'formik'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { composeValidators, email, required } from 'src/common/utils/Validate'
import { Alert } from 'src/components/ui-elements/Alert'
import { Button } from 'src/components/ui-elements/Button'
import { FormFieldWithTitle } from 'src/components/ui-groups/FormFieldWithTitle'
import { FormInputField } from 'src/components/ui-groups/FormInputField'
import { useCompanyJs } from 'src/omotion/omoti-company-js/hooks'
import { useLogin } from 'src/containers/pages/login/hooks'

type Values = {
  username: string
  password: string
}

type Props = {
  setUser: (user: CognitoUser) => void
  onClickModalOpen: () => void
}

const LoginForm: React.FC<Props> = ({ setUser, onClickModalOpen }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { doLogin, isPending, errMessage } = useLogin({
    onFirstLoggedIn: setUser,
    onLoggedIn: () => {
      const params = new URLSearchParams(location.search)
      const redirectTo = params.get('redirect')
      // リダイレクト先があればそのURLに、なければデフォルトで `/` に遷移
      navigate(redirectTo || '/', { replace: true })
    }
  })
  useCompanyJs()
  const onSubmit = React.useCallback((values: Values) => doLogin(values), [doLogin])
  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }: FormikProps<Values>): JSX.Element => {
        return (
          <form onSubmit={handleSubmit}>
            {errMessage && (
              <Alert variant="error" className={'mb-4'}>
                <p className="font-bold">{errMessage.title}</p>
                <p>{errMessage.message}</p>
              </Alert>
            )}
            <FormFieldWithTitle title="メールアドレス">
              <FormInputField
                type="email"
                id={`username`}
                name={`username`}
                className={'mb-6'}
                placeholder="メールアドレス"
                data-test="username-input"
                validate={composeValidators(required, email)}
              />
            </FormFieldWithTitle>
            <FormFieldWithTitle title="パスワード">
              <FormInputField
                id={`password`}
                type="password"
                name={`password`}
                className={'mb-6'}
                placeholder="パスワード"
                data-test="sign-in-password-input"
                validate={composeValidators(required)}
              />
            </FormFieldWithTitle>
            <Button
              name="ログイン"
              type="submit"
              color="primary"
              data-test="sign-in-button"
              blocked
              size="large"
              className={'mb-4'}
              loading={isPending}
            />
            <div className="text-right">
              <span data-test="open-modal-button" className={'linkText'} onClick={(): void => onClickModalOpen()}>
                パスワード忘れた方はこちら
              </span>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
