import { LambdaResolverErrorCode } from 'src/API'
import { MANAGEMENT_EMAIL } from 'src/common/constants'
import { OPERATION_WARNING_MESSAGE } from '../constants/OperationWarningMessage'
import {
  isAppSyncGraphQLResolverErrorResult,
  isAppSyncLambdaResolverErrorResult,
  isCognitoError,
  isCsvDownloadResolverErrorResult,
  isDynamoDBResolverError,
  isError,
  isLegacyAppSyncLambdaResolverErrorResult,
  isStringError,
  transformToAppSyncResolverError
} from './errorHandler'

export type ErrMessage = {
  code: number
  title: string
  message: string | string[]
  color?: 'error' | 'warning' | 'success' | 'info'
  isRedirect?: boolean
}

export const createErrorMessage = (err: unknown): ErrMessage => {
  // AppSync Lambda Resolver
  if (isAppSyncLambdaResolverErrorResult(err)) {
    const transformed = transformToAppSyncResolverError(err)
    const lambdaResolverError = transformed.errors?.[0].message
    if (lambdaResolverError) {
      const { errorCode } = lambdaResolverError
      switch (errorCode) {
        case LambdaResolverErrorCode.IP_ACCESS_BLOCKED:
          return {
            code: 403,
            title: 'アクセスが拒否されました',
            message: [
              '使用中のIPアドレスからの接続は許可されていません。',
              `ご不明な点がございましたら${MANAGEMENT_EMAIL}までお問い合わせください。`
            ],
            isRedirect: true
          }
        case LambdaResolverErrorCode.ACCESS_DENIED:
          return { code: 403, title: 'アクセスが拒否されました', message: '' }
        case LambdaResolverErrorCode.NOT_FOUND:
          return {
            code: 404,
            title: '該当データが見つかりません',
            message: 'ブラウザをリロードして最新の状態でお試しください。'
          }
        case LambdaResolverErrorCode.BAD_REQUEST:
          return { code: 400, title: '不正な操作が実行されました', message: '' }
        case LambdaResolverErrorCode.INVALID_STATE_OF_INVITED_USER:
          return {
            code: 400,
            title: '認証済みのアカウントのため招待メールは不要です',
            message: ''
          }
        case LambdaResolverErrorCode.DUPLICATED_ERROR:
          return {
            code: 400,
            title: '登録済みのデータと重複しているため、登録できません',
            message: ''
          }
        case LambdaResolverErrorCode.SERVER_ERROR:
          return { code: 500, title: '障害が発生しました', message: '時間を置いて再度お試しください' }
        default:
          break
      }
    }
  }
  // (旧) AppSync Lambda Resolver
  else if (isLegacyAppSyncLambdaResolverErrorResult(err)) {
    if (err?.errors?.[0]?.message === '審査の検索結果が空です') {
      return {
        code: 400,
        title: 'ダウンロードに失敗しました',
        message: OPERATION_WARNING_MESSAGE.searchNotFound,
        color: 'warning'
      }
    }
  }
  // 審査検索のパイプラインリゾルバー
  else if (isCsvDownloadResolverErrorResult(err)) {
    if (err?.errors?.[0]?.message.includes('Too many searches')) {
      return {
        code: 400,
        title: 'ダウンロードに失敗しました',
        message: OPERATION_WARNING_MESSAGE.fetchLimit({
          type: 'ダウンロード',
          limit: 1000,
          count: err?.errors?.[0]?.errorInfo as unknown as number
        }),
        color: 'warning'
      }
    }
  }
  // DynamoDBのリゾルバーのエラー
  else if (isDynamoDBResolverError(err)) {
    // Cognitoに存在していないユーザの名前/権限を変更
    if (err?.errors?.[0]?.message.includes('The conditional request failed')) {
      return {
        code: 404,
        title: '該当データが見つかりません',
        message: 'ブラウザをリロードして最新の状態でお試しください。'
      }
    }
  }
  // AppSyncのリゾルバーのエラー
  else if (isAppSyncGraphQLResolverErrorResult(err)) {
    if (err?.errors?.[0]?.message.includes('Not Authorized to access createAuthori on type Authori')) {
      return {
        code: 403,
        title: '権限がありません',
        message: ``
      }
    }
    // Not Found
    if (
      err?.errors?.[0].errorType === 'NotFound' ||
      err?.errors?.[0].message.includes('OpenSearch responded with an error: Not Found')
    ) {
      return {
        code: 404,
        title: '該当するデータが見つかりません',
        message: ``
      }
    }
  }
  // Cognitoのエラー
  else if (isCognitoError(err)) {
    const { code, message } = err
    // FORCE_CHANGE_PASSWORDのユーザーがパスワード忘れ
    if (message === 'User password cannot be reset in the current state.') {
      return {
        code: 401,
        title: '認証コードの有効期限が過ぎています',
        message: '管理者に招待メールの再送信を依頼してください。'
      }
    }

    // SignIn時またはパスワード変更でパスワードが間違っている
    if (message === 'Incorrect username or password.') {
      return {
        code: 401,
        title: '認証に失敗しました',
        message: '入力内容を確認してください。'
      }
    }

    // パスワード忘れメール送信またはパスワード忘れ > パスワード再設定
    if (message === 'Username/client id combination not found.') {
      return { code: 401, title: '認証に失敗しました', message: '入力内容を確認してください。' }
    }

    // パスワード忘れ > パスワード設定で認証コードが間違っている
    if (code === 'CodeMismatchException') {
      return { code: 401, title: '認証に失敗しました', message: '入力内容を確認してください。' }
    }

    // SignIn時にメールアドレスが間違えている
    if (message === 'User does not exist.') {
      return { code: 401, title: '認証に失敗しました', message: '入力内容を確認してください。' }
    }

    // 仮パスワードの有効期限が過ぎている
    if (message === 'Temporary password has expired and must be reset by an administrator.') {
      return {
        code: 401,
        title: '仮パスワードの有効期限が過ぎています',
        message: '管理者に招待メールの再送信を依頼してください。'
      }
    }

    // Cognitoに存在していないユーザが認証しようとした
    if (message === 'Invalid session for the user.') {
      return {
        code: 401,
        title: '認証に失敗しました',
        message: '管理者にアカウントの確認を依頼してください。'
      }
    }
    // ConfirmForgotPassword / ChangePassword パスワード変更の試行回数が多すぎる場合
    // @see https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/limits.html#resource-quotas
    if (code === 'LimitExceededException') {
      return {
        code: 401,
        title: 'パスワード変更のリクエスト回数の上限に到達しました',
        message: 'しばらく時間を置いてから再度お試しください'
      }
    }
    // パスワードポリシーの要件に満たないパスワードを設定した場合
    // https://github.com/cacco-product/omoti-console/issues/1165
    if (code === 'InvalidPasswordException') {
      return {
        code: 401,
        title: 'パスワード変更に失敗しました',
        message: 'パスワードには半角英大文字、英小文字、数字の全てを含む必要があります。'
      }
    }
  }
  // エラーインスタンス
  else if (isError(err)) {
    // ログアウト状態で認証が必要な API を叩いた場合
    if (err.message === 'No current user') {
      return { code: 401, title: 'ログアウトしました', message: '' }
    }
  }
  // throw "{string}"
  else if (isStringError(err)) {
    if (err === 'No current user') {
      // 未ログイン状態
      return { code: 401, title: 'ログアウトしました', message: '' }
    }
  }

  return { code: 500, title: '障害が発生しました', message: '時間を置いて再度お試しください' }
}
