import { useErrorHandler } from 'src/common/hooks'
import { API, graphqlOperation } from 'aws-amplify'
import {
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult
} from '@tanstack/react-query'
import type { GraphQLResult } from '@aws-amplify/api'
import { createErrorMessage } from 'src/common/helper'
import { useToast } from 'src/components/ui-elements/Toast'
import type { QUERY_KEYS } from './queryKeys'

type CommonQuery<QueryVariablesType> = {
  queryKey: QUERY_KEYS
  deps?: QueryKey
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>
  variables?: QueryVariablesType
  query: string
}

export function useCommonQuery<QueryResultType, QueryVariablesType>({
  queryKey,
  deps,
  options,
  variables,
  query
}: CommonQuery<QueryVariablesType>): UseQueryResult<QueryResultType> {
  const toast = useToast()
  const { onSetError } = useErrorHandler()
  const k = Array.isArray(deps) ? [queryKey, ...deps] : [queryKey]

  const result = useQuery({
    queryKey: k,
    queryFn: async () => {
      try {
        onSetError(undefined)
        const res = (await API.graphql(graphqlOperation(query, variables || {}))) as GraphQLResult<QueryResultType>
        return res.data
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        // 401 と 404 の時はトーストを出さない
        if (errMsg.code !== 401 && errMsg.code !== 404) {
          toast({
            text: errMsg.title,
            code: errMsg.code,
            variant: errMsg.color || 'error',
            errors
          })
        }
        throw errors
      }
    },
    ...options
  }) as UseQueryResult<QueryResultType>

  return result
}

type CommonInfiniteQuery<QueryVariablesType, QueryResultType> = {
  queryKey: QUERY_KEYS
  deps?: QueryKey
  //        Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey'>
  options?: Omit<UseInfiniteQueryOptions<QueryResultType>, 'queryKey' | 'queryFn'>
  variables?: QueryVariablesType
  query: string
}

export type CommonInfiniteQueryResult<QueryResultType> = {
  pageParam: string | undefined
  pages: QueryResultType[]
}

export function useCommonInfiniteQuery<QueryResultType, QueryVariablesType>({
  queryKey,
  deps,
  options,
  variables,
  query
}: CommonInfiniteQuery<QueryVariablesType, QueryResultType>): UseInfiniteQueryResult<
  CommonInfiniteQueryResult<QueryResultType>
> {
  const toast = useToast()
  const { onSetError } = useErrorHandler()
  const k = Array.isArray(deps) ? [queryKey, ...deps] : [queryKey]

  const result = useInfiniteQuery({
    queryKey: k,
    queryFn: async (props) => {
      const vars = {
        ...variables,
        nextToken: props.pageParam
      }
      try {
        onSetError(undefined)
        const res = (await API.graphql(graphqlOperation(query, vars))) as GraphQLResult<QueryResultType>
        return res.data
      } catch (errors) {
        const errMsg = createErrorMessage(errors)
        onSetError(errMsg)
        toast({
          text: errMsg.title,
          code: errMsg.code,
          variant: 'error',
          errors
        })
        throw errors
      }
    },
    ...(options as Omit<UseInfiniteQueryOptions<QueryResultType | undefined>, 'queryKey' | 'queryFn'>)
  }) as UseInfiniteQueryResult<CommonInfiniteQueryResult<QueryResultType>>

  return result
}
